<template>
    <div class="aplayz_mo_space_modal recommend_channel" :class="isactive">
        <div v-if="isactive === 'active'" class="background_dim"></div>
        <div class="mo_recom_header">
            <h3 class="mo_recom_header_title">추천 채널</h3>
            <img src="/media/images/icon_close_24.svg" @click="$emit('close-recom')" />
        </div>
        <ul class="mo_space_modal_body recomm">
            <li
                class="mo_space_modal_item cursor"
                v-for="(item, index) in getMobileChannelList"
                :key="'mochannel' + index"
                @click="clickJoin(item)"
            >
                <img
                    v-if="item"
                    class="mo_space_icon"
                    :class="{ active: item.channelId === gettersChannelId }"
                    :src="item.imgUrl"
                />
                <p class="mo_spact_name" v-if="item">{{ item.spaceNm }}</p>
                <div v-if="item && isPlay && gettersChannelId === item.channelId">
                    <equaliaer-comp />
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'RecommendChannelMobile',
  emits: ['close-recom'],
  props: {
    isactive: {
      type: String,
      requried: false,
      default: ''
    },
    playerinfofn: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  components: {
    'equaliaer-comp': () => import(`@/components/player/Equalizer.vue`)
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },

  mounted () {},

  methods: {
    clickJoin (item) {
      item.playerinfofn = this.playerinfofn;
      this.$store.commit('player/setActiveTab', item.activeTab);
      this.$store.commit('player/setStartChannelInfo', {
        ...item
      });
      this.$store.dispatch('player/joinChannel', item);
      this.$gatag.event('click_joinChannel_mo', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    }
  },
  computed: {
    getMobileChannelList () {
      return this.$store.getters['player/getMobileChannelList'];
    },
    isPlay () {
      return this.$store.getters.gettersIsPlay;
    },
    getMyChannelInfo () {
      return this.$store.getters['player/getMyChannelInfo'];
    },
    gettersChannelId () {
      return this.$store.getters.gettersChannelId;
    }
  }
};
</script>
<style scoped src="@/assets/css/player/mobiletemplate.css"></style>
